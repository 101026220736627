import $ from 'jquery'
import ResponsiveBootstrapToolkit from 'responsive-toolkit'

$(document).ready(dhsv_vc_submenu())

function dhsv_vc_submenu() {
  $(
    '.submenu .submenu__title > .submenu__title-link, .submenu__title-arrow'
  ).on('click', function (e) {
    if (ResponsiveBootstrapToolkit.is('<md')) {
      e.preventDefault()
      $('.submenu').toggleClass('disabled')
      $(this).parents('.submenu').removeClass('disabled')
      $(this).parents('.submenu').toggleClass('active')
    }
  })
}
