import stickElement from '../util/stickit'
import 'jquery-match-height/dist/jquery.matchHeight-min'
import ResponsiveBootstrapToolkit from 'responsive-toolkit'
import 'slick-carousel/slick/slick.min'

const dhsvCommon = {
  rowTransition() {
    setTimeout(() => {
      $('[data-vc-full-width="true"]').each(function () {
        $(this).css({
          opacity: 1,
        })
      })
    }, 450)
  },
  setCssVars() {
    setTimeout(() => {
      let adminBarHeight = 0
      if ($('#wpadminbar').length && ResponsiveBootstrapToolkit.is('>md')) {
        adminBarHeight = $('#wpadminbar').outerHeight()
      }
      const root = document.documentElement
      root.style.setProperty('--body-width', document.body.clientWidth + 'px')
      root.style.setProperty(
        '--header-banner',
        $('header.banner').outerHeight() + adminBarHeight + 'px'
      )
      root.style.setProperty(
        '--breadcrumbs-height',
        $('.breadcrumb-wrapper').outerHeight() + 'px'
      )
    }, 500)
  },

  smoothScroll(link, e) {
    let destination = 0

    if (e) e.preventDefault()
    var hash = false
    if (!link && window.location.hash) {
      hash = window.location.hash
    } else if (link && link.indexOf('#') == 0) {
      hash = link
    } else if (
      link &&
      location.pathname.replace(/^\//, '') ==
        link.pathname.replace(/^\//, '') &&
      location.hostname == link.hostname
    ) {
      hash = link.hash
    }

    if (hash && $(hash).length) {
      destination = $(hash).offset().top - 112
      history.replaceState(null, null, hash)
    }

    $('html, body').animate(
      {
        scrollTop: destination,
      },
      600
    )

    $(window).trigger('hashchange')
  },
  gfieldButtonsRemove() {
    $('.gform_wrapper').each(function () {
      if ($(this).find('.gfield .btn').length > 0) {
        $(this).find('.gform_footer').css({
          position: 'absolute',
          left: '-9999px',
        })
      }
    })
  },
  gfieldButtonsClass() {
    $('.gform_wrapper').each(function () {
      if ($(this).find('.ginput_container_fileupload').length == 0) {
        $(this).find('.btn-default').addClass('btn-form')
        $(this)
          .find('.btn-default')
          .parents('.gfmc-column')
          .addClass('gfmc-column-full')
        $(this)
          .find('.btn-default')
          .parents()
          .prev('.gfmc-column')
          .addClass('gfmc-column-full')
      }
    })
  },
  focus_gfSelect() {
    $('.ginput_container_select .gfield_select').on('focus blur', function () {
      $(this).parents('.ginput_container_select').toggleClass('active')
    })
    $('.ginput_container_select .gfield_select')
      .on('change', function () {
        let selected = $(this).find(
          'option:selected:not([selected="selected"])'
        )
        if (selected.length > 0) {
          $(this).parent().addClass('selected')
        } else {
          $(this).parent().removeClass('selected')
        }
      })
      .trigger('change')
  },
  footerMobileToggle() {
    $('.mobile-footer-title-js').on('click', function () {
      if (ResponsiveBootstrapToolkit.is('<md')) {
        $(this).next('.mobile-footer-content-js').slideToggle()
        $(this).toggleClass('active')
      }
    })
  },
  sibCheckbox() {
    if ($('.sib_signup_form').length) {
      let checkbox = $('input[name="terms"]'),
        label = $('label[for="sib-email-terms"]')
      label.on('click', function () {
        checkbox.trigger('click')
      })
    }
  },
  formsFieldsCheck() {
    let input = $('.gform_wrapper .gfield input:not(.number__input)'),
      inputNl = $('.sib_signup_form .sib-email-area input[type="email"]'),
      select = $('.gform_wrapper .gfield select'),
      textarea = $('.gform_wrapper .gfield textarea')

    input.add(textarea).on('change', function () {
      if ($(this).val().length > 0) {
        $(this).parents('.gfield').addClass('has-value')
        $(this).parents('.gfield').removeClass('active')
      } else {
        $(this).parents('.gfield').removeClass('has-value')
      }
    })

    select.on('keyup change', function () {
      if (
        $(this).is('select') &&
        $(this).find('option:selected').index() !== 0
      ) {
        $(this).parents('.gfield').addClass('has-value')
        $(this).parents('.gfield').addClass('active')
      } else {
        $(this).parents('.gfield').removeClass('has-value')
        $(this).parents('.gfield').removeClass('active')
      }
    })

    input.add(textarea).on('keyup', function () {
      if ($(this).val().length > 0) {
        $(this).parents('.gfield').addClass('active')
      } else {
        $(this).parents('.gfield').removeClass('active')
        $(this).parents('.gfield').removeClass('has-value')
      }
    })

    inputNl.on('keyup', function () {
      if ($(this).val().length > 0) {
        $(this).parents('.sib-email-area').addClass('active')
      } else {
        $(this).parents('.sib-email-area').removeClass('active')
        $(this).parents('.sib-email-area').removeClass('has-value')
      }
    })
  },
  primaryMenu() {
    $('.nav-primary ul.nav > li.menu-item > a').on('mouseover', function () {
      let heightSubmenu = 0
      if (
        $(this).parent().find('> ul.sub-menu > li.menu-item-type-yspl_win')
          .length
      ) {
        heightSubmenu = $(this)
          .parent()
          .find('> ul.sub-menu > li.menu-item-type-yspl_win')
          .outerHeight()
      } else {
        heightSubmenu = $(this).next('.sub-menu').outerHeight()
      }

      document.documentElement.style.setProperty(
        '--submenu-height',
        heightSubmenu + 'px'
      )
    })
    $('.search-js').on('click', function () {
      $('.content-info__search-wrapper').slideToggle()
    })
  },
  mobileMenu() {
    if (ResponsiveBootstrapToolkit.is('<md')) {
      $('.mobile-menu').removeClass('active')
      $('.nav-primary').hide()
    }
    $(document).on(
      'click',
      '.nav-primary ul.nav > li.menu-item.menu-item-has-children > a',
      function (e) {
        if (ResponsiveBootstrapToolkit.is('<md')) {
          e.preventDefault()
          $(this).parent().toggleClass('active')
          $(this).parents('.nav').toggleClass('active')
        }
      }
    )
    if (ResponsiveBootstrapToolkit.is('>md')) {
      $('.nav-primary').fadeIn()
      $('html').removeClass('active-mobile-menu')
      $(document).on(
        'click',
        '.nav-primary ul.nav > li.menu-item > a[href="#"]',
        function (e) {
          e.preventDefault()
        }
      )
    }
  },
  stickyNav() {
    $('.chat-button-js').on('click', function (e) {
      e.preventDefault()
      // eslint-disable-next-line no-undef
      userlike.userlikeStartChat()
    })
  },
  teaserboxSliderMobile() {
    $('.teaserbox_slider_mobile').each((i, el) => {
      $(el).slick({
        dots: true,
        arrows: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: false,
        slide: 'div',
        infinite: true,
        responsive: [
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
            },
          },
        ],
      })
    })
  },
  fileUpload() {
    $('.ginput_container_fileupload input[type="file"]').on(
      'change',
      function () {
        let str = $(this)
          .val()
          .split(/(\\|\/)/g)
          .pop()
        if (str.length > 0) {
          $(this).parents('.gfield').find('.gfield_label').addClass('active')
          $(this)
            .parents('.gfield')
            .find('.gfield_label')
            .html(
              str +
                "<span class='clear-uploads-js'><i class='las la-times'></i></span>"
            )
        } else {
          $(this).parents('.gfield').find('.gfield_label').removeClass('active')
          $(this).parents('.gfield').find('.gfield_label').html('')
        }
      }
    )

    $('.clear-uploads-js').on('click', function () {
      $(this).next('.ginput_container').find('input[type="file"]').value = ''
    })
  },
  singleProductSlider() {
    $('.single-products-slider').slick({
      dots: true,
      arrows: false,
      slidesToShow: 1,
      slidesToScroll: 1,
      autoplay: false,
      infinite: true,
    })
  },
  plusMinusInput() {
    $('.number').each((i, item) => {
      let $input = $(item).find('input'),
        inputValue = $input.val()
      if (inputValue.length == 0) {
        $(item).find('input').val(1)
      }
      $(item)
        .find('.number__minus')
        .on('click', function () {
          let count = parseInt($input.val()) - 1
          count = count < 1 ? 1 : count
          $input.val(count)
          $input.change()
          return false
        })
      $(item)
        .find('.number__plus')
        .on('click', function () {
          $input.val(parseInt($input.val()) + 1)
          $input.change()
          return false
        })
    })
  },
  overlayedVideoEvents() {
    $('.js-wpb-video-overlay').on('click', function () {
      const $overlay = $(this)

      const $video = $overlay.closest('.wpb_video_wrapper').find('video')
      if ($video.length) $video[0].play()

      const $iframe = $overlay.closest('.wpb_video_wrapper').find('iframe')

      if ($iframe.length) {
        $iframe.attr('src', $iframe.attr('src') + '&rel=0&autoplay=1')
      }

      $overlay.fadeOut(300)
    })
  },
  breadcrumbs() {
    let links = $('.breadcrumb > a')
    if (links.length > 3 && ResponsiveBootstrapToolkit.is('>md')) {
      $('.breadcrumb').addClass('expand')
    }

    $('.expand-button').on('click', function () {
      $(this).parents('.breadcrumb').removeClass('expand')
    })

    let element = $('.breadcrumb-wrapper')

    $(window).on('load resize scroll', function () {
      if (
        $('.breadcrumb-wrapper').length &&
        $(window).scrollTop() >
          $('.breadcrumb-outer-height').parents('.main').offset().top
      ) {
        element.addClass('fixed')
      } else {
        element.removeClass('fixed')
      }
      if (links.length > 2 && ResponsiveBootstrapToolkit.is('<md')) {
        $('.breadcrumb').addClass('expand')
      }
    })
  },
  bootstrapTabsScroll() {
    if (ResponsiveBootstrapToolkit.is('<md')) {
      $('.nav-pills .nav-link').on('click', function () {
        $('.nav-pills .nav-link').not(this).removeClass('active show')
      })
    }
    if ($('.nav-pills').length) {
      $('.nav-pills').each((i, el) => {
        $(el).slick({
          dots: false,
          autoplay: false,
          slide: '.nav-link',
          responsive: [
            {
              breakpoint: 9999,
              settings: 'unslick',
            },
            {
              breakpoint: 1024,
              settings: {
                arrows: false,
                slidesToShow: 3,
                slidesToScroll: 1,
              },
            },
            {
              breakpoint: 768,
              settings: {
                arrows: true,
                slidesToShow: 2,
                slidesToScroll: 1,
              },
            },
          ],
        })
      })
    }
  },
  newsletterModal() {
    let modalActive = $('.modal.active')
    if (modalActive.length) {
      $('.modal.active').modal('show')
    }
  },
  setInputFilter() {
    $(
      '.gform_legacy_markup_wrapper .gfield_list td.gfield_list_26_cell1 input'
    ).on('input keydown keyup', function (event) {
      if (event.which != 0 && event.which != 8) {
        if (event.which < 48 || event.which > 57) {
          event.preventDefault()
        }
      }
    })
  },
  init() {
    this.gfieldButtonsRemove()
    this.focus_gfSelect()
    this.footerMobileToggle()
    this.formsFieldsCheck()
    this.primaryMenu()
    this.mobileMenu()
    this.stickyNav()
    this.teaserboxSliderMobile()
    this.fileUpload()
    this.singleProductSlider()
    this.plusMinusInput()
    this.overlayedVideoEvents()
    this.breadcrumbs()
    this.bootstrapTabsScroll()
    this.gfieldButtonsClass()
    this.newsletterModal()
    this.sibCheckbox()
    this.setInputFilter()
    this.setCssVars()
    this.rowTransition()

    if (window.location.hash) {
      this.smoothScroll(window.location.hash)
    }

    $('.mobile-menu-js').on('click', function () {
      $(this).toggleClass('active')
      $('.nav-primary').slideToggle()
      $('html').toggleClass('active-mobile-menu')
    })

    // eslint-disable-next-line no-undef
    $('.gfield_list').on('click', '.add_list_item', function () {
      setTimeout(() => {
        $('.number').each((i, item) => {
          let $input = $(item).find('input'),
            inputValue = $input.val()
          if (inputValue.length == 0) {
            $(item).find('input').val(1)
          }
          $(item)
            .find('.number__minus')
            .on('click', function () {
              let count = parseInt($input.val()) - 1
              count = count < 1 ? 1 : count
              $input.val(count)
              $input.change()
              return false
            })
          $(item)
            .find('.number__plus')
            .on('click', function () {
              $input.val(parseInt($input.val()) + 1)
              $input.change()
              return false
            })
        })
      }, 200)
    })

    $('body').on(
      'click',
      'a[href^="#"]:not([href="#"],[href="#0"],[href^="#fancy-"],[data-toggle],[role="tab"],[data-vc-accordion],[data-vc-tabs])',
      e => {
        this.smoothScroll($(e.currentTarget).attr('href'), e)
      }
    )

    $('.matchHeight').matchHeight()

    $(document).on('gform_post_render', function () {
      dhsvCommon.gfieldButtonsRemove()
      dhsvCommon.gfieldButtonsClass()
    })

    $(document).ready(function () {
      let fullPath =
        window.location.protocol +
        '//' +
        window.location.host +
        '/' +
        window.location.pathname +
        window.location.search

      const $widgets = $('.widget-content')
      $widgets.each(function () {
        $(this)
          .find('.submenu-lists__item')
          .each(function () {
            let href = $(this).find('> a').attr('href')
            if (href == fullPath) {
              $(this).find('> a').addClass('text-secondary')
              $(this)
                .parents('.menu-item-has-children')
                .addClass('current-menu-item')
            }
          })
      })
    })
  },
  onResize() {
    $(window).on('resize', () => {
      this.setCssVars()
    })
  },
}

export default {
  init() {
    // JavaScript to be fired on all pages
    dhsvCommon.init()
    dhsvCommon.onResize()
  },
  finalize() {
    // JavaScript to be fired on all pages, after page specific JS is fired
    stickElement('header.banner')
  },
}
