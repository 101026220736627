import $ from 'jquery'

$(document).ready(dhsv_vc_distribution_process())

function dhsv_vc_distribution_process() {
  $('.distribution-process-arrows__item').on('mouseover', function () {
    let id = $(this).data('item-id')
    $(this)
      .parents('.distribution-process')
      .find('.distribution-process-arrows__item')
      .removeClass('active')
    $(this)
      .parents('.distribution-process')
      .find('.distribution-process-content__item')
      .removeClass('active')
      .fadeOut()

    $(this)
      .parents('.distribution-process')
      .find(`.distribution-process-content__item[data-content-id="${id}"]`)
      .addClass('active')
      .fadeIn()

    $(this).addClass('active')
  })
}
