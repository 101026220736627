'use strict'

let stickOffset = 0
if ($('body').hasClass('admin-bar')) {
  stickOffset = 32
}
let stickClass = 'set-sticky'
let stickInterval

/**
 * stickElement
 * @param  {string} selector jquery selector
 * @return void
 */
export default function stickElement(selector, options = {}) {
  setStick(selector, options)
  $(window).on('scroll resize', function (event) {
    setStick(selector, options, event)
  })
  // $(window).on('scroll resize', function(event) {
  //   throttle(setStick, 10, [selector, options, event])
  // })
}

const setStick = (selector, options, event) => {
  if (options && options.length) {
    //console.log(options) // eslint-disable-line
  }
  // console.log(event); // eslint-disable-line
  $(selector).each(function (index, el) {
    var $holder = $(el).closest('[class*="__holder"]')
    if (!$holder.length) {
      $(el).wrap('<div class="set-sticky__holder"></div>')
      $holder = $(el).closest('[class*="__holder"]')
      // console.log('holder created') // eslint-disable-line
      $holder.css({
        marginTop: $(el).css('margin-top'),
        marginBottom: $(el).css('margin-bottom'),
      })
    }

    $(el).css({
      width: $holder.width(),
    })

    if (!$(el).data('initial-offset') && $(el).data('initial-offset') != 0) {
      $(el).data('initial-offset', $holder.offset().top)
      // $(el).data('initial-offset', $(el).offset().top)
      // console.log('initial offset is set') // eslint-disable-line
    }

    if (!$(el).data('initial-height')) {
      $(el).data('initial-height', $(el).outerHeight())
      // console.log('initial height is set') // eslint-disable-line
      setHolderHeight($(el), $holder)
    }

    if (event && event.type == 'resize') {
      // Update initial offset on resize
      $(el).data('initial-offset', $holder.offset().top)
      // console.log('initial offset updated') // eslint-disable-line

      if ($(el).data('initial-height') != $(el).outerHeight()) {
        $(el).data('initial-height', $(el).outerHeight())
        // console.log('initial height updated') // eslint-disable-line
        setHolderHeight($(el), $holder)
      }
    }
    //console.log(stickOffset) //eslint-disable-line
    if (
      $(window).scrollTop() + stickOffset > $(el).data('initial-offset') &&
      !$(el).hasClass(stickClass)
    ) {
      $(el).addClass(stickClass).css({
        marginTop: 0,
        marginBottom: 0,
        position: 'fixed',
        top: stickOffset,
      })
      // console.log('stick') // eslint-disable-line
      // updateStickOffset()
    } else if (
      $(window).scrollTop() + stickOffset - $(el).data('actual-height') <=
        $(el).data('initial-offset') &&
      $(el).hasClass(stickClass)
    ) {
      $(el).removeClass(stickClass).css({
        marginTop: '',
        marginBottom: '',
        position: '',
        top: '',
      })
      // console.log('unstick') // eslint-disable-line
    }
    // console.log($(el).data('initial-offset')) //eslint-disable-line
  })

  if (typeof stickInterval === 'undefined') {
    stickInterval = setInterval(function () {
      updateStickOffset()
    }, 15)
    // window.requestAnimationFrame(updateStickOffset)
  }
}

const setHolderHeight = ($el, $holder) => {
  $holder.css({
    height: $el.data('initial-height'),
  })
}

const updateStickOffset = () => {
  stickOffset = 0
  if ($('body').hasClass('admin-bar') && $(window).width() > 1281) {
    stickOffset = 32
    $('html').removeClass('has-sticky')
  } else {
    stickOffset = 0
    $('html').addClass('has-sticky')
  }

  $('.set-sticky').each(function (index, el) {
    $(el).data('actual-height', $(el).outerHeight())
    $(el).css({ top: stickOffset })
    stickOffset += $(el).outerHeight()
  })
}
