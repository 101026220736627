import $ from 'jquery'

$(document).ready(dhsv_vc_accordion())

function dhsv_vc_accordion() {
  $('.accordion-expand__button').on('click', function () {
    let opened = $(this).data('opened'),
      closed = $(this).data('closed')

    if (!$(this).hasClass('active')) {
      $(this).html(opened + '<i class="las la-angle-down"></i>')
      $(this).addClass('active')
      $(this)
        .parents('.accordion')
        .find('.accordion__item:not(.active) .accordion__item-title')
        .trigger('click')
    } else {
      $(this).html(closed + '<i class="las la-angle-down"></i>')
      $(this).removeClass('active')
      $(this)
        .parents('.accordion')
        .find('.accordion__item.active .accordion__item-title')
        .trigger('click')
    }
  })
  $('.accordion__item-title').on('click', function () {
    $(this).parents('.accordion__item').toggleClass('active')
    $(this)
      .parents('.accordion__item')
      .find('.accordion__item-content')
      .slideToggle()
  })
}
