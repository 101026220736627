import $ from 'jquery'

$.fn.isInViewport = function () {
  let elementTop = $(this).offset().top,
    elementBottom = elementTop + $(this).outerHeight() / 2,
    viewportTop = $(window).scrollTop(),
    viewportHalf = viewportTop + $(window).height() / 2

  return elementBottom > viewportTop && elementTop < viewportHalf
}

$(window).on('load resize scroll', function () {
  if ($('.history-company').length && $('.history-company').isInViewport()) {
    $('.history-company .history-company__content').each(function (i) {
      setTimeout(() => {
        $(this).addClass('active')
      }, 300 * i)
    })
  }
})
