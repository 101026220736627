import $ from 'jquery'
import ResponsiveBootstrapToolkit from 'responsive-toolkit'

$(document).ready(dhsv_vc_process_text_form())

function dhsv_vc_process_text_form() {
  $('.process-text-form__title').on('click', function () {
    if (ResponsiveBootstrapToolkit.is('<md')) {
      $(this).toggleClass('active')
      $(this)
        .parents('.process-text-form')
        .find('.process-text-form__descr')
        .slideToggle()
    }
  })
}
