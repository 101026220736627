/* global System */
import $ from 'jquery'

$(document).ready(function () {
  if ($('.dhsv_vc_accordion').length) {
    dhsv_vc_tabs()
    malihu()
  }
})

function dhsv_vc_tabs() {
  $(
    '.nav[aria-orientation="horizontal"],.nav[aria-orientation="vertical"]'
  ).each(function () {
    let ind = $(this).find('.nav-link.active').index()
    $(this).parents('.accordion').find('.tab__item').eq(ind).addClass('active')
  })
}

function malihu() {
  System.import(
    'malihu-custom-scrollbar-plugin/jquery.mCustomScrollbar.concat.min'
  ).then(() => {
    const args = {
      axis: 'y',
      scrollbarPosition: 'outside',
      setTop: 0,
      contentTouchScroll: true,
      alwaysShowScrollbar: false,
    }
    $('.mCustomScrollbar').mCustomScrollbar(args)
  })
}
