/* Don't forget to add string 'products' to function combineReducers in './reducer.js' */
import { WP_API_BASE } from '../../shared/constants'
import isEmpty from 'lodash/isEmpty'

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: null,
  maxNumPages: 0,
  lastPost: '',
  posts: [],
  filters: {
    categories: [],
  },
  strings: {},
  perPage: 5,
  activePage: 1,
  activeFilters: {},
  sort: '',
  hasFilters: false,
}
const LOAD_NEWS_DATA = 'LOAD_NEWS_DATA'
const UPLOAD_NEWS_DATA = 'UPLOAD_NEWS_DATA'
const LOAD_NEWS_DATA_SUCCESS = 'LOAD_NEWS_DATA_SUCCESS'
const LOAD_NEWS_DATA_FAILURE = 'LOAD_NEWS_DATA_FAILURE'

export default (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case LOAD_NEWS_DATA:
      return {
        ...state,
        isLoading: true,
      }

    case LOAD_NEWS_DATA_SUCCESS:
      return {
        ...state,
        isLoading: false,
        isLoaded: true,
        posts: [...payload.posts],
        activePage: payload.page,
        sort: payload.sort,
        hasFilters: payload.hasFilters,
        activeFilters: payload.activeFilters,
        maxNumPages: parseInt(payload.maxNumPages),
      }

    case LOAD_NEWS_DATA_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: true,
      }

    case UPLOAD_NEWS_DATA:
      return {
        ...state,
        posts: payload.data.posts,
        filters: payload.data.filters,
        lastPost: payload.data.lastPost,
        maxNumPages: parseInt(payload.data.maxNumPages),
        strings: payload.strings,
      }

    default:
      return state
  }
}

export const loadPosts = (page, filters, sort = initialState.sort) => (
  dispatch,
  getState
) => {
  dispatch({ type: LOAD_NEWS_DATA })
  // Rest API Params
  const { perPage } = getState().news
  const hasFilters = !isEmpty(filters)
  let params = {
    perPage: perPage,
    sort: sort,
    offset: (page - 1) * perPage + (hasFilters ? 0 : 1),
  }
  if (hasFilters) {
    params = { ...params, ...filters }
  }

  let apiBaseUrl = WP_API_BASE
  let url_params = '/'
  if (params) {
    let i = 0
    for (let key in params) {
      // eslint-disable-next-line no-prototype-builtins
      params.hasOwnProperty(key)
        ? (url_params += (i === 0 ? '?' : '&') + key + '=' + params[key])
        : false
      i++
    }
  }

  //console.log('request', `${apiBaseUrl}news${url_params}`) //eslint-disable-line

  return fetch(`${apiBaseUrl}news${url_params}`, {
    method: 'GET',
  })
    .then(response => {
      if (response.ok && response.status === 200) {
        return response.json()
      }
      throw new Error(response.statusText)
    })
    .then(payload => {
      dispatch({
        payload: {
          posts: payload.posts,
          maxNumPages: payload.maxNumPages,
          page,
          sort,
          hasFilters,
          activeFilters: filters,
        },
        type: LOAD_NEWS_DATA_SUCCESS,
      })

      return payload
    })
    .catch(error => {
      if (process.env.NODE_ENV === 'development') {
        console.error(error) //eslint-disable-line
      }
      dispatch({
        error: error,
        type: LOAD_NEWS_DATA_FAILURE,
      })
    })
}

export const uploadData = ({ data, content, strings }) => dispatch => {
  dispatch({
    payload: {
      strings,
      data,
      content,
    },
    type: UPLOAD_NEWS_DATA,
  })
}

export const changeSelect = sort => (dispatch, getState) => {
  dispatch(
    loadPosts(getState().news.activePage, getState().news.activeFilters, sort)
  )
}
