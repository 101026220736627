import Parallax from 'parallaxjs'
import Rellax from 'rellax/rellax.min'

const parallax = new Parallax(document.querySelectorAll('.layer'))
parallax.init()
window.addEventListener('scroll', () => parallax.onScroll())
window.addEventListener('resize', () => parallax.onResize())
let rellax = new Rellax('.rellax-second')

$(document).ready(rellax)
